import React from "react"
import { Link, withRouter, NavLink } from "react-router-dom"
import { withTranslation } from "react-i18next"
import "./Navigation.css"
import logo from "../../assets/images/dmi_logo.png"
import logoMainPage from "../../assets/images/dmi_white.png"

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const languages = [
  { code: "en", name: "English", flag: "🇬🇧" },
  { code: "nl", name: "Netherlands", flag: "🇳🇱" },
  { code: "id", name: "Indonesia", flag: "🇮🇩" },
]

class Navigation extends React.Component {
  static defaultProps = {
    isMainPage: false,
  }
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
      isOpen: false,
      languageMenuOpen: false,
    }
  }

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  toggleLanguageMenu = (e) => {
    e.preventDefault()
    this.setState({
      languageMenuOpen: !this.state.languageMenuOpen,
    })
  }

  changeLanguage = (langCode) => {
    const { i18n } = this.props

    // Save to localStorage
    localStorage.setItem("language", langCode)

    // Change language
    i18n.changeLanguage(langCode)

    // Close menus
    this.setState({
      languageMenuOpen: false,
      isOpen: false,
    })
  }

  getCurrentLanguage = () => {
    const { i18n } = this.props
    return languages.find((lang) => lang.code === i18n.language) || languages[0]
  }

  componentDidMount() {
    // Get saved language from localStorage
    const savedLanguage = localStorage.getItem("language")
    if (savedLanguage && savedLanguage !== this.props.i18n.language) {
      this.props.i18n.changeLanguage(savedLanguage)
    }

    let elementId = document.getElementById("navbar")
    let navbarId = document.getElementById("navbar-nav")
    let navlinkMainPageClasses =
      document.getElementsByClassName("nav-link-main-page")
    let hamburgerId = document.getElementById("hamburger")
    let logoId = document.getElementById("logo")

    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky")
        navbarId.classList.add("is-sticky")

        if (this.props.isMainPage) {
          hamburgerId.classList.add("colored-hamburger")
          hamburgerId.classList.remove("white-hamburger")
          logoId.src = logo
        }

        // Change each 'nav-link-main-page' class to 'nav-link' if scrolled past 170
        Array.from(navlinkMainPageClasses).forEach((navLink) => {
          navLink.classList.add("nav-link-main-page-scrolled")
        })
        window.history.pushState("", document.title, window.location.pathname)
      } else {
        elementId.classList.remove("is-sticky")
        navbarId.classList.remove("is-sticky")

        if (this.props.isMainPage) {
          hamburgerId.classList.remove("colored-hamburger")
          hamburgerId.classList.add("white-hamburger")
          logoId.src = logoMainPage
        }

        // Change each 'nav-link' class back to 'nav-link-main-page' if scrolled above 170
        Array.from(navlinkMainPageClasses).forEach((navLink) => {
          navLink.classList.remove("nav-link-main-page-scrolled")
        })
      }
    })
    window.scrollTo(0, 0)
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen })

  render() {
    const { collapsed, languageMenuOpen } = this.state
    const { t, isMainPage } = this.props
    const currentLanguage = this.getCurrentLanguage()

    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show"
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right"
    const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`

    return (
      <header id="header" className="header-area">
        <div id="navbar" className="elkevent-nav">
          <nav
            className="navbar navbar-expand-md navbar-light"
            id="navbar-nav"
            style={isMainPage ? { position: "absolute", width: "100%" } : {}}
          >
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img
                  src={isMainPage ? logoMainPage : logo}
                  alt="logo"
                  style={{
                    maxWidth: "259px",
                    maxHeight: "56px",
                  }}
                  id="logo"
                />
              </Link>

              <button
                onClick={this.toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span
                  className={`navbar-toggler-icon ${
                    isMainPage ? "white-hamburger" : "colored-hamburger"
                  }`}
                  id="hamburger"
                ></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                  {/* Your existing nav items */}
                  <li className="nav-item">
                    <Link
                      to="/"
                      onClick={this.toggleOpen}
                      className={isMainPage ? "nav-link-main-page" : "nav-link"}
                    >
                      {t("nav.home")}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/about"
                      onClick={this.toggleOpen}
                      className={isMainPage ? "nav-link-main-page" : "nav-link"}
                    >
                      {t("nav.about")}
                    </Link>
                  </li>

                  {/* Registration Dropdown */}
                  <li className="nav-item">
                    <Link
                      to="#"
                      onClick={this.toggleOpen}
                      className={isMainPage ? "nav-link-main-page" : "nav-link"}
                    >
                      {t("nav.registration")}
                    </Link>
                    <ul className={menuClass}>
                      <li className="nav-item">
                        <a href="#" rel="noopener noreferrer">
                          {t("nav.visitorTicket")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/business-meeting"
                          className="nav-link"
                          onClick={this.toggleNavbar}
                        >
                          {t("nav.businessMeeting")}
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  {/* Show Guide Dropdown */}
                  <li className="nav-item">
                    <Link
                      to="#"
                      onClick={this.toggleOpen}
                      className={isMainPage ? "nav-link-main-page" : "nav-link"}
                    >
                      {t("nav.showGuide")}
                    </Link>
                    <ul className={menuClass}>
                      <li className="nav-item">
                        <NavLink
                          to="/agenda"
                          className="nav-link"
                          onClick={this.toggleNavbar}
                        >
                          {t("nav.agenda")}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/exhibitor-application"
                          className="nav-link"
                          onClick={this.toggleNavbar}
                        >
                          {t("nav.exhibitorApplication")}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#"
                          className="nav-link"
                          rel="noopener noreferrer"
                          onClick={this.toggleNavbar}
                        >
                          {t("nav.exhibitorCatalogue")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/sponsors"
                          className="nav-link"
                          onClick={this.toggleNavbar}
                        >
                          {t("nav.sponsor")}
                        </NavLink>
                      </li>
                      {/* <li className="nav-item">
                        <NavLink
                          to="/delegation"
                          className="nav-link"
                          onClick={this.toggleNavbar}
                        >
                          {t("nav.delegation")}
                        </NavLink>
                      </li> */}
                      <li className="nav-item">
                        <NavLink
                          to="/hotels"
                          className="nav-link"
                          onClick={this.toggleNavbar}
                        >
                          {t("nav.hotels")}
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/news"
                      onClick={this.toggleNavbar}
                      className={isMainPage ? "nav-link-main-page" : "nav-link"}
                    >
                      {t("nav.news")}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/gallery"
                      onClick={this.toggleNavbar}
                      className={isMainPage ? "nav-link-main-page" : "nav-link"}
                    >
                      {t("nav.gallery")}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/contact"
                      onClick={this.toggleNavbar}
                      className={isMainPage ? "nav-link-main-page" : "nav-link"}
                    >
                      {t("nav.contact")}
                    </Link>
                  </li>

                  {/* Language Selector */}
                  <li className="nav-item">
                    <Link
                      to="#"
                      className={isMainPage ? "nav-link-main-page" : "nav-link"}
                      onClick={this.toggleLanguageMenu}
                      style={{
                        background: "none",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        cursor: "pointer",
                        fontSize: "15px",
                        fontWeight: 500,
                        // color: "#2b2b2b",
                        textDecoration: "none",
                        transition: "all 0.5s",
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      <span>{currentLanguage.flag}</span>
                      <span>{currentLanguage.name}</span>
                    </Link>

                    <ul
                      className={`dropdown-menu${
                        languageMenuOpen ? " show" : ""
                      }`}
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "0",
                        zIndex: 1000,
                        minWidth: "10rem",
                        padding: "0.5rem 0",
                        margin: "0.125rem 0 0",
                        fontSize: "15px",
                        backgroundColor: "#ffffff",
                        border: "1px solid rgba(0, 0, 0, 0.15)",
                        borderRadius: "0.25rem",
                        display: languageMenuOpen ? "block" : "none",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      }}
                    >
                      {languages.map((lang) => (
                        <li key={lang.code}>
                          <button
                            className="dropdown-item"
                            onClick={() => this.changeLanguage(lang.code)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5rem",
                              width: "100%",
                              padding: "8px 15px",
                              border: "none",
                              background: "none",
                              cursor: "pointer",
                              fontSize: "15px",
                              color: "#2b2b2b",
                              textDecoration: "none",
                              transition: "all 0.3s ease",
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#f8f9fa"
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor =
                                "transparent"
                            }}
                          >
                            <span>{lang.flag}</span>
                            <span>{lang.name}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    )
  }
}

// First withTranslation, then withRouter
export default withTranslation()(withRouter(Navigation))
