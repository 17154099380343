import React from "react"
import lax from "lax.js"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/autoplay"
class About extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()
    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }

  render() {
    const { t } = this.props

    return (
      <section>
        <div className="container">
          <div className="home-about-description">
            <h1>{t("about.joinEvent")}</h1>
            <img src="header1.jpg" />
            <h2 style={{ fontSize: "48px" }}>{t("about.title")}</h2>
            <p className="text-black font-sans text-[11pt]">
              The <i>Discovering the Magnificence of Indonesia Expo 2024</i>{" "}
              successfully showcased the cultural and economic potential of
              Indonesia in the heart of Europe. Held from{" "}
              <strong>November 28 to December 1, 2024</strong>, at the{" "}
              <strong>
                Jaarbeurs Exhibition and Convention Center in Utrecht,
                Netherlands
              </strong>
              , the event highlighted the rich heritage, innovative industries,
              and boundless opportunities of the archipelago.
            </p>

            <hr className="my-4" />
            <div className="home-about-description">
              <img src="header2.jpg" />
              <h2 style={{ fontSize: "48px" }}>A Multifaceted Agenda</h2>
              <p className="line-height[1.38] mb-3 mt-3" dir="ltr">
                <span className="bg-transparent text-black font-sans text-[11pt]">
                  The four-day event offered a vibrant mix of activities
                  catering to diverse audiences, including:
                </span>
              </p>
              <ul className="list-disc pl-12 mb-0 mt-0">
                <li className="text-black font-sans text-[11pt]">
                  <strong>Exhibition</strong>: A showcase of Indonesian SMEs,
                  products, and innovations.
                </li>
                <li className="text-black font-sans text-[11pt]">
                  <strong>Business Matching and Forums</strong>: Facilitating
                  partnerships and collaborations.
                </li>
                <li className="text-black font-sans text-[11pt]">
                  <strong>Tourism Village Visits</strong>: Promoting 20+ Green
                  Village Destinations to global audiences.
                </li>
                <li className="text-black font-sans text-[11pt]">
                  <strong>Cultural Performances and Workshops</strong>:
                  Featuring 10+ traditional performances, fashion shows, and
                  hands-on workshops on Indonesian arts and crafts.
                </li>
                <li className="text-black font-sans text-[11pt]">
                  <strong>Food and Beverage Stalls</strong>: Highlighting
                  Indonesia's famous culinary offerings.
                </li>
                <li className="text-black font-sans text-[11pt]">
                  <strong>Talk Shows</strong>: Engaging discussions on topics
                  such as sustainable tourism and export opportunities.
                </li>
              </ul>
            </div>

            <hr className="my-4" />
            <div className="home-about-description">
              <img src="header3.jpg" />
              <h2 style={{ fontSize: "48px" }}>Key Achievements</h2>
              <p className="text-black font-sans text-[11pt]">
                The expo achieved remarkable milestones in its mission to
                connect Indonesia with international markets and promote its
                cultural heritage:
              </p>
              <ol className="list-decimal pl-5 mt-4">
                <li className="text-black font-sans text-[11pt]">
                  <strong>Strong Business Outcomes:</strong>
                  <ul className="list-disc pl-5">
                    <li>
                      Over 50 B2B meetings successfully connected Indonesian
                      entrepreneurs with international buyers.
                    </li>
                    <li>
                      Potential business transactions worth €1.7 million
                      (approximately 30 billion IDR) were generated from SME
                      products and tourism deals.
                    </li>
                    <li>
                      Collaboration was established with importers and warehouse
                      owners to serve as product aggregators for the Indonesian
                      market in Western Europe.
                    </li>
                  </ul>
                </li>
                <li className="text-black font-sans text-[11pt]">
                  <strong>Significant Foot Traffic:</strong>
                  <ul className="list-disc pl-5">
                    <li>
                      The event drew over 2,000 visitors, including business
                      leaders, cultural enthusiasts, and members of the
                      Indonesian diaspora.
                    </li>
                  </ul>
                </li>
                <li className="text-black font-sans text-[11pt]">
                  <strong>Highlighting Indonesian SMEs and Tourism:</strong>
                  <ul className="list-disc pl-5">
                    <li>
                      More than 35 top Indonesian SMEs showcased their products.
                    </li>
                    <li>
                      Promotions of 20+ Green Village Destinations captivated
                      international audiences.
                    </li>
                  </ul>
                </li>
                <li className="text-black font-sans text-[11pt]">
                  <strong>Cultural and Fashion Highlights:</strong>
                  <ul className="list-disc pl-5">
                    <li>
                      The "Wastra Nusantara & Modest Fashion Festival"
                      introduced over 10 renowned local brands to the European
                      market.
                    </li>
                    <li>
                      Visitors enjoyed cultural performances and Indonesian
                      culinary delights, which became the main attractions.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>

            <hr className="my-4" />
            <div>
              <div className="home-about-description">
                <img src="header4.jpg" />
                <h2 style={{ fontSize: "48px" }}>
                  Full Support from Stakeholders
                </h2>
                <p className="text-black font-sans text-[11pt]">
                  The success of the expo was made possible by the collaborative
                  efforts of the Indonesian government, sponsors, and the
                  Indonesian diaspora in the Netherlands. Their contributions
                  ensured a well-organized event that bridged cultures and
                  strengthened economic ties.
                </p>
              </div>

              <hr className="my-4" />
              <div className="home-about-description">
                <h2 style={{ fontSize: "48px" }}>
                  A Step Towards Greater Visibility
                </h2>
                <p className="text-black font-sans text-[11pt]">
                  The <i>Discovering the Magnificence of Indonesia Expo 2024</i>{" "}
                  proved to be a strategic platform for showcasing
                  Indonesia&apos;s cultural richness and economic potential on a
                  global stage. With its success, the event has laid a strong
                  foundation for future collaborations, further enhancing
                  Indonesia&apos;s presence in international markets.
                </p>
                <p className="text-black font-sans text-[11pt]">
                  As the excitement for{" "}
                  <i>Discovering the Magnificence of Indonesia Expo 2025</i>{" "}
                  builds, this year&apos;s event will be remembered as a
                  testament to Indonesia&apos;s spirit of innovation,
                  collaboration, and cultural pride.{" "}
                  <b>Stay tuned for more milestones in 2025!</b>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="gallery-layout">
            <h2>{t("about.gallery")}</h2>
            <Link to={"gallery"}>
              {t("homePage.seeMore")}{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"24"}
                height={"24"}
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l14 0" />
                <path d="M13 18l6 -6" />
                <path d="M13 6l6 6" />
              </svg>
            </Link>
          </div>
        </div>

        <div className="home-about-swiper-container">
          <Swiper
            className="home-about-swiper"
            spaceBetween={32}
            slidesPerView={"auto"}
            freeMode={true}
          >
            <SwiperSlide>
              <img src="gallery1.JPG" alt="slideshow 1" />
            </SwiperSlide>

            <SwiperSlide>
              <img src="gallery2.jpg" alt="slideshow 1" />
            </SwiperSlide>
            {/* <SwiperSlide>
            <img src={GalleryTwo} alt="slideshow 2" />
          </SwiperSlide> */}
            <SwiperSlide>
              <img src="gallery3.jpg" alt="slideshow 3" />
            </SwiperSlide>
            {/* <SwiperSlide>
            <img src={GalleryFour} alt="slideshow 4" />
          </SwiperSlide> */}

            <SwiperSlide>
              <img src="gallery4.jpg" alt="slideshow 4" />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="home-yt-gallery">
          <div className="container">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/ODItmbk_doQ"
              title={t("about.video")}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation()(About)
