import React from "react"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

class MainBanner extends React.Component {
  render() {
    const { t } = this.props
    return (
      <div className="page-title-area item-bg1">
        <div className="container">
          <h1>
            Discovering the Magnificence of Indonesia Expo 2024: A Celebration
            of Culture and Commerce
          </h1>
          <span>{t("mainBanner.subtitle")}</span>
          <ul>
            <li>
              <Link to="/">{t("mainBanner.breadcrumb.home")}</Link>
            </li>
            <li>{t("mainBanner.breadcrumb.about")}</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default withTranslation()(MainBanner)
